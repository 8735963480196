import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
// import { getItemSlug } from "../utils/item";
import qs from "query-string";

// Components
import ServerTimes from "../components/ServerTimes";
import CategoryNavigation from "../components/CategoryNavigation";
import GearRow from "../components/GearRow";

const Form = styled.form`
  width: 100%;
`;

const SearchField = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    flex: 1 1 100%;
  }
`;

const CardBody = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Column = styled.div`
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const PageButton = styled.span`
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
`;

export default class ItemsPage extends React.Component {
  constructor(props) {
    super(props);
    const { data, location } = props;
    const gear = data?.allContentfulGear?.nodes;

    let filteredGear = gear;
    let query = null;
    if (location.search) {
      const parsed = qs.parse(location.search);
      query = parsed.query || null;

      if (query && query.length > 0) {
        filteredGear = this._filter(query, gear);
      }
    }

    const pages = Math.ceil(filteredGear.length / 10);

    this.state = {
      pages,
      page: 1,
      limit: 10,
      query,
      filteredGear,
      // Input
      userQuery: query && query.length > 0 ? query : "",
    };

    this.userInputField = null;
  }

  componentDidMount() {
    if (this.userInputField) {
      this.userInputField.focus();
    }
  }

  handleUserInput(e) {
    this.setState({
      userQuery: e.target.value,
    });
  }

  onClickHandler(e) {
    e.preventDefault();
    const { userQuery } = this.state;
    const { location } = this.props;

    if (userQuery.length > 0) {
      this._filterGear(userQuery);

      window.history.replaceState(
        {},
        `${userQuery} - Black Desert Mobile Item Search - Item Database for BDM Global`,
        `${location.pathname}?query=${userQuery}`,
      );
    } else {
      this._filterGear(false);

      window.history.replaceState(
        {},
        "Black Desert Mobile Item Search - Item Database for BDM Global",
        `${location.pathname}`,
      );
    }
  }

  _filterGear(query) {
    const gear = this.props.data?.allContentfulGear?.nodes;

    if (!query) {
      this.setState({
        page: 1,
        pages: Math.ceil(gear.length / this.state.limit),
        query,
        filteredGear: gear,
      });

      return;
    }

    const filteredGear = this._filter(query, gear);

    this.setState({
      page: 1,
      pages: Math.ceil(filteredGear.length / this.state.limit),
      query,
      filteredGear,
    });
  }

  _filter(query, gear) {
    return gear.filter(item => {
      const searchString = `${item.name} ${item.type}`.toLowerCase();
      return searchString.includes(query.toLowerCase());
    });
  }

  changePage(page) {
    this.setState({
      page,
    });
  }

  render() {
    const { filteredGear, limit, pages, page, query, userQuery } = this.state;
    const { data } = this.props;
    const collections = data?.allContentfulGearCollection?.nodes;

    const metaDescription = `Item Database & Wiki for MMO Black Desert Mobile. Full statistics and enhancement information.`;

    const pagelist = Array.from(Array(5), (e, i) => -2 + i + page);
    const positivePagelist = pagelist.filter(val => val > 0 && val <= pages);

    return (
      <>
        <Helmet
          title={`Black Desert Mobile Item Search - Item Database for BDM Global`}
          description={metaDescription}
        >
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
          />

          <meta name="description" content={metaDescription} />

          <meta
            property="og:title"
            content={`Black Desert Mobile Item Search - Item Database for BDM Global`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content="https://bdm.wiki/items" />
          <meta
            property="og:image"
            content="https://bdm.wiki/BDMwiki-logo.jpg"
          />
          <meta property="og:type" content="”website”" />
        </Helmet>

        <div className="container mt-3 mb-5">
          <div className="row">
            <div className="d-none d-lg-block col-12 col-lg-4">
              <CategoryNavigation categories={collections} />

              <ServerTimes />
            </div>

            <div className="col-12 col-lg-8">
              <div className="row">
                <Column className="col-12">
                  <div className="card">
                    <h1 className="card-header h6">
                      Black Desert Mobile Item Search
                    </h1>
                    <CardBody className="card-body">
                      <SearchField>
                        <Form onSubmit={this.onClickHandler.bind(this)}>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search for an item"
                              aria-label="Search for an item"
                              aria-describedby="search-button"
                              value={userQuery}
                              autoCorrect="off"
                              onChange={this.handleUserInput.bind(this)}
                              ref={r => (this.userInputField = r)}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-dark"
                                type="submit"
                                id="search-button"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </Form>
                      </SearchField>
                      {query && (
                        <p>
                          Searching for: {query} - {filteredGear.length} found
                        </p>
                      )}
                      {!query && <p>Showing all {filteredGear.length} items</p>}
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center flex-wrap">
                          {page - 3 > 1 && (
                            <>
                              <li className={`page-item d-none d-sm-block`}>
                                <PageButton
                                  className="page-link"
                                  onClick={this.changePage.bind(this, 1)}
                                >
                                  {1}
                                </PageButton>
                              </li>
                              <li>
                                <PageButton className="page-link disabled spacer">
                                  ...
                                </PageButton>
                              </li>
                            </>
                          )}
                          {positivePagelist.map((selectedIndex, i) => {
                            const pageNumber = selectedIndex;
                            return (
                              <li
                                className={`page-item ${pageNumber === page &&
                                  `disabled`}`}
                                key={i}
                              >
                                <PageButton
                                  className="page-link"
                                  onClick={this.changePage.bind(
                                    this,
                                    pageNumber,
                                  )}
                                >
                                  {pageNumber}
                                </PageButton>
                              </li>
                            );
                          })}
                          {page + 3 <= pages && (
                            <>
                              <li>
                                <PageButton className="page-link disabled spacer">
                                  ...
                                </PageButton>
                              </li>
                              <li className={`page-item d-none d-sm-block`}>
                                <PageButton
                                  className="page-link"
                                  onClick={this.changePage.bind(this, pages)}
                                >
                                  {pages}
                                </PageButton>
                              </li>
                            </>
                          )}
                        </ul>
                      </nav>
                      <ul
                        className="d-flex flex-wrap"
                        style={{ magin: 0, padding: 0, listStyle: "none" }}
                      >
                        {filteredGear
                          .slice(limit * page - limit, limit * page)
                          .map((g, i) => {
                            return <GearRow gear={g} key={i} />;
                          })}
                      </ul>
                    </CardBody>
                  </div>
                </Column>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const pageQuery = graphql`
  query ItemsPageQuery {
    allContentfulGearCollection {
      nodes {
        name
        baseSlug
        collection {
          name
        }
      }
    }
    allContentfulGear {
      nodes {
        name
        type
        baseAp
        baseDp
        sockets
        weight
        updatedAt
        createdAt
        rarity
        subStats {
          name
          type
          amount
        }
        images {
          title
          description
          file {
            url
          }
          fluid(maxWidth: 500) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`;
